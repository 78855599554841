import React, {useEffect, useState} from "react";
import "../css/Panel.css";
import Logo from "../assets/Gearup logo  6.svg";
import Logo2 from "../assets/Gearup logo  5.png";
import {TbHomeSearch, TbReport} from "react-icons/tb";
import {BsCalendar3} from "react-icons/bs";
import {BiSolidUser} from "react-icons/bi";
import {AiFillSetting} from "react-icons/ai";
import Home from "./menu/Home/Home";
import Calendar from "./menu/Calender";
import OnlineProfile from "./menu/OnlineProfile";
import Report from './menu/Report'
import {axiosInstance} from '../utils/axiosInstance';
import {endPoints} from '../utils/endPoints'
import SignOut from '../assets/sign-out.png'
import {useFutsalId} from "../reducers/ui/FutsalIdHook";
import {useIsOwner} from "../reducers/ui/isOwnerHook";
import {useFutsal} from "../reducers/ui/FutsalHook";
import { TiThMenu } from "react-icons/ti";

function Panel() {
    let [isOwner, setIsOwner] = useIsOwner();
    let [futsal,setFutsal]=useFutsal();
    let [futsalId, setFutsalId] = useFutsalId();
    const [availability, setAvailability] = useState(true)
    const [activeButton, setActiveButton] = useState("homeButton");
    const [stats, setStats] = useState([]);
    let datta = {};
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    // Function to toggle mobile nav visibility
    const toggleMobileNav = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };


    const id = sessionStorage.getItem('id')

    useEffect(() => {
        setIsOwner(sessionStorage.getItem('isOwner'))
    }, []);


    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };


    useEffect(() => {

        console.log("stat", stats);
        console.log("id", datta[0]?.futsalId);
        setFutsalId(id)
    }, []);


    function toggleChange() {
        const updatedAvailability = !availability; // Store the updated value
        setAvailability(updatedAvailability); // Update the state

        axiosInstance
            .put(`${endPoints.EDIT_FUTSAL_DETAILS}${futsalId}/update-availability`, {available: updatedAvailability}) // Use the updated value
            .then((response) => {
                console.log("API response:", response.data.futsal);
                console.log(response.data.status);
            })
            .catch((error) => {
                // Handle errors here
                console.error("API Error:", error);
            });
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const endPoint = endPoints.GET_FUTSAL_DETAILS;
                const response = await axiosInstance.get(`${endPoint}${id}`);
                setFutsal(response.data.futsal)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

    const handleSignOut = () => {
        const confirmSignOut = window.confirm("Are you sure you want to sign out?");
        if (confirmSignOut) {
            sessionStorage.clear();
            window.location.reload(false);

            window.location.href = '/';
        } else {
            // Do nothing or handle cancellation
        }
    };


    return (
        <>
            <div className="mobile-top-nav">
                <button onClick={toggleMobileNav}><TiThMenu size="30"/></button>
                <img className="logo-top-nav" src={Logo2} alt="gear up logo"></img>
            </div>
            <div className={`mobile-nav ${isMobileNavOpen ? "open-nav" : ""}`}>
                <div className="top-nav-mobile">
                    <img onClick={toggleMobileNav} className="logo-top-nav" src={Logo} alt="gear up logo"></img>

                    <div className="nav-links-mobile">
                        <button
                            className={`panel-btn-mobile ${
                                activeButton === "homeButton" ? "activate" : ""
                            }`}
                            onClick={() => handleButtonClick("homeButton")}
                        >
                            <TbHomeSearch size={20}/> &nbsp; &nbsp; Home
                        </button>
                        <button
                            className={`panel-btn-mobile ${
                                activeButton === "calenderButton" ? "activate" : ""
                            }`}
                            onClick={() => handleButtonClick("calenderButton")}
                        >
                            <BsCalendar3 size={20}/> &nbsp; &nbsp;Calendar
                        </button>
                        {isOwner === "true" && (
                            <button
                                className={`panel-btn-mobile ${
                                    activeButton === "profileButton" ? "activate" : ""
                                }`}
                                onClick={() => handleButtonClick("profileButton")}
                            >
                                <BiSolidUser size={20}/> &nbsp; &nbsp;Online Profile
                            </button>
                        )}
                        {isOwner === "true" && (

                            <button
                                className={`panel-btn-mobile ${
                                    activeButton === "reportButton" ? "activate" : ""
                                }`}
                                onClick={() => handleButtonClick("reportButton")}
                            >
                                <TbReport size={20}/> &nbsp; &nbsp;Reports
                            </button>
                        )}
                        {isOwner === "true" && (
                            <button
                                className={`panel-btn-mobile ${
                                    activeButton === "settingButton" ? "activate" : ""
                                }`}
                                onClick={() => handleButtonClick("settingButton")}
                            >
                                <AiFillSetting size={20}/> &nbsp; &nbsp;Setting
                            </button>)}
                    </div>
                </div>
                <div className="bottom-nav">
                    <img src={SignOut} alt="sign-out" onClick={handleSignOut}></img>
                    {isOwner === "true" && (
                        <label className="toggle">
                            <input className="toggle-checkbox" type="checkbox" onChange={toggleChange}
                                   checked={availability}></input>
                            <div className="toggle-switch"></div>
                        </label>)}
                </div>
            </div>
            <div className="portal-container">
                <div className="nav-container">
                    <nav>
                        <div className="top-nav">
                            <img className="logo" src={Logo} alt="gear up logo"></img>
                            <div className="nav-links">
                                <button
                                    className={`panel-btn ${
                                        activeButton === "homeButton" ? "activate" : ""
                                    }`}
                                    onClick={() => handleButtonClick("homeButton")}
                                >
                                    <TbHomeSearch size={20}/> &nbsp; &nbsp; Home
                                </button>
                                <button
                                    className={`panel-btn ${
                                        activeButton === "calenderButton" ? "activate" : ""
                                    }`}
                                    onClick={() => handleButtonClick("calenderButton")}
                                >
                                    <BsCalendar3 size={20}/> &nbsp; &nbsp;Calendar
                                </button>
                                {isOwner === "true" && (
                                    <button
                                        className={`panel-btn ${
                                            activeButton === "profileButton" ? "activate" : ""
                                        }`}
                                        onClick={() => handleButtonClick("profileButton")}
                                    >
                                        <BiSolidUser size={20}/> &nbsp; &nbsp;Online Profile
                                    </button>
                                )}
                                {isOwner === "true" && (

                                    <button
                                        className={`panel-btn ${
                                            activeButton === "reportButton" ? "activate" : ""
                                        }`}
                                        onClick={() => handleButtonClick("reportButton")}
                                    >
                                        <TbReport size={20}/> &nbsp; &nbsp;Reports
                                    </button>
                                )}
                                {isOwner === "true" && (
                                    <button
                                        className={`panel-btn ${
                                            activeButton === "settingButton" ? "activate" : ""
                                        }`}
                                        onClick={() => handleButtonClick("settingButton")}
                                    >
                                        <AiFillSetting size={20}/> &nbsp; &nbsp;Setting
                                    </button>)}
                            </div>
                        </div>
                        <div className="bottom-nav">
                            <img src={SignOut} alt="sign-out" onClick={handleSignOut}></img>
                            {isOwner === "true" && (
                                <label className="toggle">
                                    <input className="toggle-checkbox" type="checkbox" onChange={toggleChange}
                                           checked={availability}></input>
                                    <div className="toggle-switch"></div>
                                </label>)}
                        </div>
                    </nav>
                </div>


                <div className="panel-cont">
                    {activeButton === "homeButton" && (
                        <Home id={id}/>
                    )}
                    {activeButton === "calenderButton" && (
                        <Calendar id={id}/>
                    )}
                    {activeButton === "profileButton" && (
                        <OnlineProfile id={id}/>
                    )}
                    {activeButton === "reportButton" && (
                        <Report id={id}/>
                    )}
                </div>
            </div>
        </>
    );
}

export default Panel;
