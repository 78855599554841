import { createStore, combineReducers } from "redux";
import futsalReducer from "./FutsalReducer";
import isOwnerReducer from "./IsownerReducer";
import bookingReducer from "./BookingReducer";
import futsalIdReducer from "./FutsalIdReducer";
import { composeWithDevTools } from "redux-devtools-extension";







const rootReducer = combineReducers({
    isOwner: isOwnerReducer,
    futsal: futsalReducer,
    futsalId:futsalIdReducer,
    bookings:bookingReducer
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
