import React, { useEffect, useState } from "react";
import Bookings from "../Bookings";
import "../../../css/Home.css";
import { endPoints } from "../../../utils/endPoints";
import { axiosInstance } from "../../../utils/axiosInstance";
import ManualBooking from "../ManualBooking";
import { useIsOwner } from "../../../reducers/ui/isOwnerHook";
import { Revenue } from "./Revenue";
import { TodayBooking } from "./TodayBooking";
import { LastThreeMonths } from "./LastThreeMonths";

const Home = (props) => {
  const [isOwner, setIsOwner] = useIsOwner();
  const [bookings, setBookings] = useState([]);
  const [last3MonthsCount, setLast3MonthsCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [totalAmountLast3Months, setTotalAmountLast3Months] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endPoint = endPoints.GET_BOOKINGS;
        const response = await axiosInstance.get(`${endPoint}${props.id}`);
        setBookings(response.data.allBookings);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props.id]);

  useEffect(() => {
    console.log(bookings);
  }, [bookings]);

  const currentDate = new Date();
  const monthInLetters = currentDate.toLocaleDateString("en-IN", {
    month: "long",
  });
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

  useEffect(() => {
    const last3MonthsBookings = bookings.filter((booking) => {
      const bookingDate = new Date(booking.playStartTime);
      bookingDate.setHours(bookingDate.getHours() - 5);
      bookingDate.setMinutes(bookingDate.getMinutes() - 30);
      return bookingDate >= threeMonthsAgo && bookingDate <= currentDate;
    });

    const totalAmount = last3MonthsBookings.reduce((acc, booking) => {
      return acc + booking.price;
    }, 0);

    const todayBookings = bookings.filter((booking) => {
      const bookingDate = new Date(booking.playStartTime);
      bookingDate.setUTCHours(bookingDate.getUTCHours() - 5);
      bookingDate.setUTCMinutes(bookingDate.getUTCMinutes() - 30);
      return (
        bookingDate.getDate() === currentDate.getDate() &&
        bookingDate.getMonth() === currentDate.getMonth() &&
        bookingDate.getFullYear() === currentDate.getFullYear()
      );
    });

    setLast3MonthsCount(last3MonthsBookings.length);
    setTodayCount(todayBookings.length);
    setTotalAmountLast3Months(totalAmount);
  }, [bookings]);

  return (
    <div className="home-container">
      <div className="home-header">
        <div className="text-header">
          <h1>Sales Overview</h1>
          <p>view your current sales and summary</p>
        </div>
        <div className="filter-header"></div>
      </div>
      <div className="home-body">
        <div className="details-card">
          <Revenue
            totalAmountLast3Months={totalAmountLast3Months}
            currentDate={currentDate}
            monthInLetters={monthInLetters}
            isOwner={isOwner}
          />
          <TodayBooking todayCount={todayCount} />
          <LastThreeMonths
            last3MonthsCount={last3MonthsCount}
            currentDate={currentDate}
            monthInLetters={monthInLetters}
          />
        </div>
        <div className="home-body-bottom">
          <div className="booking-list">
            <Bookings id={props.id}/>
          </div>
          {/*<div className="calender"></div>*/}
          {/*<h1>Add Bookings</h1>*/}

          <ManualBooking/>
        </div>
      </div>
    </div>
  );
};

export default Home;
