import {useDispatch, useSelector} from "react-redux";
import {addIsOwner} from "../../actions/IsOwnerAction";


export function useIsOwner(){
    const dispatch = useDispatch();
    return [
        useSelector(state => state.isOwner),
        isOwner => dispatch(addIsOwner(isOwner))
    ]
}