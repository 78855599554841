import {endPoints} from "./endPoints";
import {axiosInstance} from "./axiosInstance";
import moment from "moment-timezone";

const fetchAvailableTimeSlots = async (date,futsal,selectedCourt,futsalId,setAvailability) => {
    console.log("futsal data", futsal);
    console.log("booking court", selectedCourt);

    if (futsal) {
        try {
            const endPoint = endPoints.GET_TIME_SLOT;
            const response = await axiosInstance.post(endPoint, {
                futsalId: futsalId,
                courtName: selectedCourt,
                playTime: date.startValue,
                futsalStartTime: futsal.startTime,
                futsalEndTime: futsal.endTime,
            });

            const availableTimeSlots = response.data.availableTimeSlots || [];
            const holdBookings = response.data.holdBookings || [];

            const mergedSlots= {};

            availableTimeSlots.forEach((slot) => {
                const currentTime = moment();
                const slotTime = moment.tz(
                    `${moment(date.startValue).format("YYYY-MM-DD")} ${slot.startTime}`,
                    "YYYY-MM-DD HH:mm",
                    "Asia/Colombo"
                );
                if (slotTime.isSameOrAfter(currentTime)) {
                    mergedSlots[slot.startTime] = { ...slot, type: "available" };
                }
            });

            // Add hold bookings to the merged object with a type "hold"
            holdBookings.forEach((booking) => {
                mergedSlots[booking.startTime] = { ...booking, type: "hold" };
            });

            console.log("Merged timeslots", mergedSlots);

            setAvailability(mergedSlots);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
};


const calculateTotalPrice = (futsal,selectedTimeSlots) => {
    let totalPrice = 0;

    if (futsal && futsal.pricePerHour) {
        const { day, night } = futsal.pricePerHour;

        selectedTimeSlots.forEach((startTime) => {
            const startHour = parseInt(startTime.split(":")[0], 10);
            const isPM = startTime.split(" ")[1].toUpperCase() === "PM";

            // Adjust startHour for 12-hour format
            let adjustedStartHour =
                isPM && startHour === 12
                    ? startHour
                    : isPM
                        ? startHour + 12
                        : !isPM && startHour === 12
                            ? startHour + 12
                            : startHour;
            const isNightTime = adjustedStartHour >= 18 || adjustedStartHour < 5;
            const hourlyPrice = isNightTime ? night : day;

            totalPrice += hourlyPrice;
        });

    }
    return totalPrice;
};

const getStartAndEndTime = (selectedTimeSlots,date) => {
    console.log("selected-buttons", selectedTimeSlots);
    if (selectedTimeSlots.length > 0) {
        const startTime = selectedTimeSlots[0].split("-")[0];
        const endTime = selectedTimeSlots[selectedTimeSlots.length - 1].split("-")[1];
        const formattedStartTime = moment(startTime, ["hh:mm A"]).format("HH:mm");
        const formattedEndTime = moment(endTime, ["hh:mm A"]).format("HH:mm");

        console.log("selected-buttons time", formattedStartTime, formattedEndTime);
        const yearMonthDay = moment(date.startValue).format("YYYY-MM-DD");
        const playStartTime = moment
            .tz(
                yearMonthDay + " " + formattedStartTime.replace(".", ":"),
                "YYYY-MM-DD HH:mm",
                "Asia/Colombo"
            )
            .add(5.5, "hours")
            .utc()
            .format();
        console.log("play-start",playStartTime)
        const playEndTime = moment
            .tz(
                yearMonthDay + " " + formattedEndTime.replace(".", ":"),
                "YYYY-MM-DD HH:mm",
                "Asia/Colombo"
            )
            .add(5.5, "hours")
            .utc()
            .format();


        return {
            formattedStartTime,
            formattedEndTime,
            playStartTime,
            playEndTime,
        };
    }
};

const handleButtonClick = (
    startTime,
    availability,
    selectedTimeSlots,
    setSelectedTimeSlots

) => {
    const slotData = availability[startTime];


    if (slotData.type === "hold") {
        return;
    }

    const start12Hour = moment(startTime, "HH:mm").format("hh:mm A");
    const end12Hour = moment(slotData.endTime, "HH:mm").format("hh:mm A");

    const selectedSlot = `${start12Hour} - ${end12Hour}`;

    const isSelected = selectedTimeSlots.includes(selectedSlot);

    let updatedSelection;

    if (isSelected) {
        updatedSelection = selectedTimeSlots.filter((slot) => slot !== selectedSlot);
    } else {
        updatedSelection = [...selectedTimeSlots, selectedSlot];
    }

    updatedSelection.sort((a, b) => {
        const timeA = moment(a.split(" - ")[0], "hh:mm A").valueOf();
        const timeB = moment(b.split(" - ")[0], "hh:mm A").valueOf();
        return timeA - timeB;
    });

    setSelectedTimeSlots(updatedSelection);
    setTimeout(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    }, 1000);
};




export {fetchAvailableTimeSlots,calculateTotalPrice,getStartAndEndTime,handleButtonClick}