import React, { useState, useEffect } from "react";
import "../../css/Booking.css";
import { axiosInstance } from "../../utils/axiosInstance";
import { endPoints } from "../../utils/endPoints";

const Bookings = (props) => {
  const [bookings, setBookings] = useState([]);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endPoint = endPoints.GET_BOOKINGS;
        const response = await axiosInstance.get(`${endPoint}${props.id}`);
        setBookings(response.data.allBookings);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props.id]);

  useEffect(() => {
    const currentDate = new Date();
    const filteredBookings = bookings.filter((booking) => {
      const bookingDate = new Date(booking.playStartTime);
      bookingDate.setHours(bookingDate.getHours() - 5);
      bookingDate.setMinutes(bookingDate.getMinutes() - 30);
      return (
          bookingDate.toDateString() === selectedDate.toDateString() &&
          bookingDate >= currentDate &&
          bookingDate <= new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 59, 59)
      );
    }).sort((a, b) => new Date(a.playStartTime) - new Date(b.playStartTime));

    setUpcomingBookings(filteredBookings);
  }, [bookings, selectedDate]);

  const handleDateChange = (e) => {
    const selected = new Date(e.target.value);
    setSelectedDate(selected);
  };

  return (
      <div className="booking-container">
        <div className="date-picker-container">
          <label htmlFor="date">Select Date:</label>
          <input
              type="date"
              id="date"
              value={selectedDate.toISOString().substr(0, 10)}
              min={new Date().toISOString().substr(0, 10)} // Set min attribute to today's date
              onChange={handleDateChange}
          />
        </div>
        <div className="table-head">
          {/*for {selectedDate.toDateString()}*/}
          <h2>Upcoming bookings </h2>
        </div>
        <table>
          <thead>
          <tr>
            <th>Booking id</th>
            <th>Time</th>
            <th>Name</th>
            <th>Court Name</th>
          </tr>
          </thead>
          <tbody>
          {upcomingBookings.map((upBooking) => (
              <tr key={upBooking.bookingId}>
                <td
                    className={
                      upBooking.paid ? "paid-booking" : "unpaid-booking"
                    }
                >
                  {upBooking.bookingId}
                </td>
                <td>
                  {upBooking.startTime}-{upBooking.endTime}
                </td>
                <td>{upBooking.userName}</td>
                <td className="court">{upBooking.courtName}</td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>
  );
};

export default Bookings;
