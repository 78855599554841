import React from "react";

export  const  Revenue=(props)=>{
    return (

            props.isOwner === "true" && (
                <div className="detail-card">
                    <h4>Revenue</h4>
                    <h2>Rs.{props.totalAmountLast3Months}</h2>
                    <p>vs. 3 months prior to {props.currentDate.getDate()} {props.monthInLetters}</p>
                </div>
            )

    )
}