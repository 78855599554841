import { IS_OWNER } from "../actionTypes/ActionTypes";


const addIsOwner = (isOwner) => {
    return {
        type: IS_OWNER,
        payload:isOwner
    };
};

// const deleteItem = () => {
//     return {
//         type: DELETE_ITEM,
//     };
// };

export { addIsOwner };