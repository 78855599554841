export const endPoints ={
    VERIFY_ADMIN:"/admin/signIn",
    ADD_BOOKING:"/booking/addBooking/",
    GET_BOOKINGS:"/booking/getBooking/",
    GET_CUSTOMER_DETAILS:"/users/getUser/",
    GET_FUTSAL_DETAILS:"/futsal/courts/",
    EDIT_FUTSAL_DETAILS:"/futsal/courts/",
    GET_FUTSAL_IMAGE:"/futsal/image/",
    EDIT_FUTSAL_IMAGE:"/images/update-image/",
    GET_TIME_SLOT:"/booking/check-time-slot/"
}
