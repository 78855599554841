import React from "react";

export const LastThreeMonths=(props)=>{
    return (
        <div className="detail-card">
            <h4>Bookings</h4>
            <h2>{props.last3MonthsCount}</h2>
            <p>vs. 3 months prior to {props.currentDate.getDate()} {props.monthInLetters}</p>
        </div>
    )
}