import {useDispatch, useSelector} from "react-redux";
import {addFutsal} from "../../actions/FutsalAction";


export function useFutsal(){
    const dispatch = useDispatch();
    return [
        useSelector(state => state.futsal),
        futsal => dispatch(addFutsal(futsal))
    ]
}