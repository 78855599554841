import './App.css';
import {Route, Routes} from 'react-router-dom'
import Login from './components/Login';
import Panel from './components/Panel';
import {Provider} from "react-redux";
import store from "./reducers/Store";


function App() {
    const isLogin = sessionStorage.getItem('status') === 'loggedIn';
    console.log("Status", isLogin)

    return (
        <Provider store={store}>
            <div className="App">
                <Routes>
                    <Route path='/' element={isLogin ? <Panel/> : <Login/>}/>
                    <Route path='/adminPanel' element={<Panel/>}/>

                </Routes>
            </div>
        </Provider>
    );
}

export default App;

