import axios from 'axios';

const apiPort = process.env.REACT_APP_PORT_KEY;

// const baseURL = `${window.location.origin.includes("http://localhost:") ? apiPort : window.location.origin}`
const baseURL="https://gear-up-panel.vercel.app/"
export const axiosInstance = axios.create({
    baseURL
});

console.log("API PORT", apiPort)
console.log("baseURL", baseURL)
