import React, {useEffect, useState} from 'react';
import '../../css/OnlineProfile.css';
import {AiFillDelete} from "react-icons/ai";
import {endPoints} from "../../utils/endPoints";
import {axiosInstance} from "../../utils/axiosInstance";
import {useFutsal} from "../../reducers/ui/FutsalHook";


const OnlineProfile = (props) => {
    let [futsal, setFutsal] = useFutsal();
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        url: '',
        place: '',
        contact: {
            email: '',
            mobile: '',
        },
        startTime: '',
        endTime: '',
        courtType: [],
        pricePerHour: {
            day: '',
            night: ''
        },
        description:'',
        courts: [{courtName: ''}],
        district:'',

    });
    console.log("form data", formData)


    useEffect(() => {
        setFormData(futsal)
    }, [])


    const handleChange = (event) => {
        const {name, value} = event.target;
        if (name === "email" || name === "mobile") {
            setFormData((prevData) => ({
                ...prevData,
                contact: {
                    ...prevData.contact,
                    [name]: value,
                },
            }));
        } else if (name === "day" || name === "night") {
            setFormData((prevData) => ({
                ...prevData,
                pricePerHour: {
                    ...prevData.pricePerHour,
                    [name]: value,
                },
            }));
        } else if (name === "courtType") {
            setFormData((prevData) => ({
                ...prevData,
                courtType: value === 'both' ? ['Football', 'Cricket'] : [value],
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    const handleCourtChange = (event, index) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            courts: prevData.courts.map((court, i) =>
                i === index ? {...court, [name]: value} : court
            ),
        }));
    };


    const addCourt = () => {
        setFormData((prevData) => ({
            ...prevData,
            courts: [...prevData.courts, {courtName: ''}],
        }));
    };

    const removeCourt = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            courts: prevData.courts.filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const endPoint = endPoints.GET_FUTSAL_DETAILS;
            const response = await axiosInstance.put(`${endPoint}${props.id}`, formData);
            console.log('Futsal updated successfully');
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    return (
        <form onSubmit={handleSubmit} className="form-container">
            <div className='input-fields'>

                <div className='form-fields'>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Address:</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Place:</label>
                    <input
                        type="text"
                        name="place"
                        value={formData.place}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>District:</label>
                    <select
                        className="select"
                        name="district"
                        value={ formData.district}
                        onChange={handleChange}
                    >
                        {/*<option value="">Select Court Type</option>*/}
                        <option value="Colombo">Colombo</option>
                        <option value="Kandy">Kandy</option>
                        <option value="Galle">Galle</option>
                    </select>
                </div>
                <div className='form-fields'>
                    <label>Email:</label>
                    <input
                        type="text"
                        name="email"
                        value={formData.contact.email}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Mobile Number:</label>
                    <input
                        type="text"
                        name="mobile"
                        value={formData.contact.mobile}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Start Time:</label>
                    <input
                        type="time"
                        name="startTime"
                        value={formData.startTime}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>End Time:</label>
                    <input
                        type="time"
                        name="endTime"
                        value={formData.endTime}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Court Type:</label>
                    <select
                        className="select"
                        name="courtType"
                        value={formData.courtType.length === 2 ? 'both' : formData.courtType[0]}
                        onChange={handleChange}
                    >
                        {/*<option value="">Select Court Type</option>*/}
                        <option value="Football">Football</option>
                        <option value="Cricket">Cricket</option>
                        <option value="both">Both</option>
                    </select>
                </div>
                <div className='form-fields'>
                    <label>Location URL:</label>
                    <input
                        type="text"
                        name="url"
                        value={formData.url}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Price per hour (Day):</label>
                    <input
                        type="text"
                        name="day"
                        value={formData.pricePerHour.day}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Price per hour (Night):</label>
                    <input
                        type="text"
                        name="night"
                        value={formData.pricePerHour.night}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Description</label>
                    <textarea
                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-fields'>
                    <label>Courts:</label>
                    {formData.courts.map((court, index) => (
                        <div key={index} className='form-fields-courts'>
                            <input
                                rows="24"
                                cols="50"
                                // type="text"
                                name="courtName"
                                value={court.courtName}
                                onChange={(e) => handleCourtChange(e, index)}
                            />
                            <button type="button" onClick={() => removeCourt(index)} className='remove-btn'>
                                <AiFillDelete size={15}/>
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={addCourt} className="button-add">
                        +
                    </button>
                </div>
            </div>

            <button type="submit">Update Futsal</button>
        </form>
    );
}

export default OnlineProfile;


