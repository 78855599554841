import React, {useEffect, useState} from "react";
import {Datepicker, DatepickerEvent} from "@meinefinsternis/react-horizontal-date-picker";
import {enIN} from "date-fns/locale";
import {useFutsal} from "../../reducers/ui/FutsalHook";
import {useFutsalId} from "../../reducers/ui/FutsalIdHook";
import BookingForm from "./BookingForm";
import moment from "moment-timezone";
import {
    calculateTotalPrice,
    fetchAvailableTimeSlots,
    getStartAndEndTime,
    handleButtonClick
} from "../../utils/HelperFunctions";

const ManualBooking = () => {
    let [futsal, setFutsal] = useFutsal();
    let [futsalId, setFutsalId] = useFutsalId();
    const [showBookingForm, setShowBookingForm] = useState(false);
    const [date, setDate] = React.useState({
        startValue: null,
        endValue: null,
        rangeDates: [],
    });
    const [selectedCourt, setSelectedCourt] = useState();
    const [availability, setAvailability] = useState({});
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);


    useEffect(() => {
        if (date.startValue) {
            fetchAvailableTimeSlots(date,futsal,selectedCourt,futsalId,setAvailability)
        }
    }, [date]);


    const {formattedStartTime, formattedEndTime,playStartTime,playEndTime} = selectedTimeSlots.length > 0 && getStartAndEndTime(selectedTimeSlots,date);

    const handleBookClick = () => {
        setShowBookingForm(true);
    };

    useEffect(() => {
        if (futsal && futsal.courts && futsal.courts.length > 0) {
            setSelectedCourt(futsal.courts[0]?.courtName || '');
        }
    }, [futsal]);



    const handleChange = (d) => {
        const [startValue, endValue, rangeDates] = d;

        const sriLankanTime = new Date(startValue.getTime() - (startValue.getTimezoneOffset() * 60000));

        setDate((prev) => ({...prev, endValue, startValue: sriLankanTime, rangeDates}));
        console.log('clicked', sriLankanTime, endValue, rangeDates);
        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }, 1000);
    };


    const handleCourtButton = (court) => {
        const courtName = court.courtName;
        setSelectedCourt(courtName);
    };

    useEffect(() => {
        console.log("selected Court", selectedCourt);
        console.log("Selected Time slots ", selectedTimeSlots)
    }, [selectedCourt, selectedTimeSlots])

    return (
        <>
            {showBookingForm && (
                <div className="popup-form">
                    <div className="popup-inner">
                        <button onClick={() => setShowBookingForm(false)}>Close</button>
                        <BookingForm
                            id={futsal.futsalId}
                            courtName={selectedCourt}
                            price={calculateTotalPrice(futsal,selectedTimeSlots)}
                            startTime={formattedStartTime}
                            endTime={formattedEndTime}
                            playStartTime={playStartTime}
                            playEndTime={playEndTime}
                            setShowBookingForm={setShowBookingForm}/>
                    </div>
                </div>
            )}

            {(futsal && futsal.courts && futsal.courts.length > 0) && (
                <div className="court-buttons">
                    {futsal.courts.map((court, index) => (
                        <button
                            key={index}
                            onClick={() => handleCourtButton(court)}
                            style={{
                                backgroundColor: selectedCourt === court.courtName ? '#081B2C' : '#817575',
                                color: selectedCourt === court.courtName ? 'white' : 'black'
                            }}
                        >
                            {court.courtName}
                        </button>
                    ))}
                </div>
            )}

            <div className="manual-booking-container">
                <Datepicker
                    onChange={handleChange}
                    locale={enIN}
                    startValue={date.startValue}
                    endValue={date.startValue}

                />
            </div>

            {availability && (
                <div className="time-slot-buttons">
                    {Object.keys(availability)
                        .sort((a, b) => {
                            const timeA = moment(a, "HH:mm").valueOf();
                            const timeB = moment(b, "HH:mm").valueOf();
                            return timeA - timeB;
                        })
                        .map((startTime, index) => (
                            <button
                                key={index}
                                onClick={() =>
                                    handleButtonClick(
                                        startTime,
                                        availability,
                                        selectedTimeSlots,
                                        setSelectedTimeSlots
                                    )
                                }
                                className={`date-time-booking-court-btn ${
                                    selectedTimeSlots.includes(
                                        `${moment(startTime, "HH:mm").format(
                                            "hh:mm A"
                                        )} - ${moment(
                                            availability[startTime].endTime,
                                            "HH:mm"
                                        ).format("hh:mm A")}`
                                    )
                                        ? "selected"
                                        : ""
                                }
                                ${
                                    availability[startTime].type === "hold"
                                        ? "hold-booking"
                                        : ""
                                }`}
                                style={{
                                    backgroundColor:
                                        availability[startTime].type === "hold"
                                            ? "orange"
                                            : "",
                                    color:
                                        availability[startTime].type === "hold"
                                            ? "white"
                                            : "",
                                    border:
                                        availability[startTime].type === "hold"
                                            ? "orange"
                                            : "",
                                    cursor:
                                        availability[startTime].type === "hold"
                                            ? "not-allowed"
                                            : "",
                                }}
                            >
                                {`${moment(startTime, "HH:mm").format(
                                    "hh:mm A"
                                )} - ${moment(
                                    availability[startTime].endTime,
                                    "HH:mm"
                                ).format("hh:mm A")}`}
                            </button>
                        ))}
                </div>
            )}


            {selectedCourt && selectedTimeSlots.length > 0 && (
                <button className="booking-btn" onClick={handleBookClick}>Book</button>
            )}


        </>)
}

export default ManualBooking