import React, {useState, useEffect} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "../../css/Calender.css";
import {axiosInstance} from "../../utils/axiosInstance";
import {endPoints} from "../../utils/endPoints";


const Calendar = (props) => {
    const apiPort = process.env.REACT_APP_PORT_KEY;
    const [events, setEvents] = useState([]);
    const [calendarView, setCalendarView] = useState("dayGridMonth");
    const [showPopup, setShowPopup] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);


    useEffect(() => {
        const apiUrl = `${endPoints.GET_BOOKINGS}${props.id}`;

        axiosInstance
            .get(apiUrl)
            .then((response) => {
                const bookingData = response.data.allBookings;

                const transformedEvents = bookingData.map((booking) => {
                    const start = new Date(booking.playStartTime);
                    const end = new Date(booking.playEndTime);

                    // Subtract 5.30 hours (5 hours and 30 minutes)
                    start.setHours(start.getHours() - 5);
                    start.setMinutes(start.getMinutes() - 30);
                    end.setHours(end.getHours() - 5);
                    end.setMinutes(end.getMinutes() - 30);

                    return {
                        id: booking.email,
                        title: `court ${booking.courtName}`,
                        start: start.toISOString(),
                        end: end.toISOString(),
                        backgroundColor: booking.status === "complete" ? "green" : "orange",
                    };
                });
                console.log("transss data", transformedEvents);
                setEvents(transformedEvents);
            })
            .catch((error) => {
                console.error("Error fetching restaurant:", error);
            });
    }, []);


    const fetchCustomerDetails = (eventEmail) => {
        const apiUrl = `${endPoints.GET_CUSTOMER_DETAILS}${eventEmail}`;
        axiosInstance.get(apiUrl)
            .then((response) => {
                setCustomerDetails(response.data.user);
                console.log("cus Details: ",response.data.user)
            })
            .catch((error) => {
                console.error("Error fetching customer details:", error);
            });
    };


    return (
        <div className="calender-container">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={calendarView}
                events={events}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: 'short' // AM/PM
                }}
                eventClick={(info) => {
                    console.log("Event clicked:", info.event);
                    console.log("info",info)
                    setSelectedEvent(info.event);
                    setShowPopup(true);
                    const eventId = info.event.id;
                    fetchCustomerDetails(eventId);
                }}
            />
            {showPopup && customerDetails && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Customer Details</h3>
                        <p>Name: {customerDetails.name}</p>
                        <p>Email: {customerDetails.email}</p>
                        <p>Phone: {customerDetails.phone}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Calendar;
