import React, {useState, useEffect} from "react";
import moment from "moment-timezone";
import {endPoints} from "../../utils/endPoints";
import {axiosInstance} from "../../utils/axiosInstance";
import '../../css/BookingForm.css'


const BookingForm=(props)=>{
    const getCurrentSLSTDateTime = () => {
        return moment.tz('Asia/Colombo').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    };
    const currentDateTime = getCurrentSLSTDateTime();
    const [formData, setFormData] = useState({
        userName: '',
        email: '',
        futsalId: props.id,
        bookingTime: currentDateTime,
        playStartTime: props.playStartTime,
        playEndTime: props.playEndTime,
        startTime: props.startTime,
        endTime: props.endTime,
        courtName: props.courtName,
        price: props.price,
        status:"oneTimeBooking",
        paid:false

    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const addBooking = async () => {
        try {
            const endPoint = endPoints.ADD_BOOKING;
            const response = await axiosInstance.post(endPoint, formData);
            console.log(response.data)
            props.setShowBookingForm(false)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(formData)
        await addBooking();
    };
    return(
        <>
            <form onSubmit={handleSubmit}>
                <div className="input-field">
                    <label>Customer Name:</label>
                    <input type="text" name="userName" value={formData.userName} onChange={handleChange} />
                </div>
                <div className="input-field">
                    <label>Email:</label>
                    <input type="text" name="email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="input-field">
                    <label>Play Start Time:</label>
                    <input type="text" name="playStartTime" value={props.playStartTime} onChange={handleChange} />
                </div>
                <div className="input-field">
                    <label>Play End Time:</label>
                    <input type="text" name="playEndTime" value={props.playEndTime} onChange={handleChange} />
                </div>
                <div className="input-field">
                    <label>Court Name:</label>
                    <input type="text" name="courtName" value={props.courtName} onChange={handleChange} />
                </div>
                <div className="input-field">
                    <label>Price:</label>
                    <input type="number" name="price" value={props.price} onChange={handleChange} />
                </div>
                <button type="submit">Submit</button>
            </form>
        </>
    )
}
export default BookingForm;