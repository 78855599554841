import {useDispatch, useSelector} from "react-redux";
import {addFutsalId} from "../../actions/FutsalIdAction";


export function useFutsalId(){
    const dispatch = useDispatch();
    return [
        useSelector(state => state.futsalId),
        futsalId => dispatch(addFutsalId(futsalId))
    ]
}