import React, {useEffect, useState} from "react";
import "../css/Login.css";
import {axiosInstance} from "../utils/axiosInstance";
import {endPoints} from "../utils/endPoints";
import {useNavigate,} from "react-router-dom";
import {BeatLoader} from "react-spinners";
import {CSSProperties} from "react";

function Login() {
    const navigate = useNavigate();
    const [adminDetails, setAdminDetails] = useState({
        email: "",
        passwordHash: "",
    });
    const [isLoading,setIsLoading]=useState(false);
    const override: CSSProperties = {
        position: "absolute",
        top: "50%",
        right: "45%",
        zIndex:"99"

    };

    const mobileOverride: CSSProperties = {
        position: "absolute",
        top: "50%",
        right: "38%",
        zIndex:"99"
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        sendDataToApi(adminDetails);
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setAdminDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const sendDataToApi = (formData) => {
        setIsLoading(true)
        axiosInstance
            .post(endPoints.VERIFY_ADMIN, formData)
            .then((response) => {
                console.log("API response:", response.data.futsal);
                console.log(response.data.status);
                sessionStorage.setItem('id', response.data.futsal[0].futsalId)


                // const serializedData = JSON.stringify(response.data.futsal);

                if (response.data.status === "ok") {
                    if (response.data.role==="OWNER"){
                        sessionStorage.setItem('isOwner',JSON.parse(true));

                    }
                    else {
                        sessionStorage.setItem('isOwner',JSON.parse(false));
                    }
                    setIsLoading(false)


                    sessionStorage.setItem('status', 'loggedIn')
                    navigate({
                        pathname: "/adminPanel",
                    });
                }
            })
            .catch((error) => {
                console.error("API error:", error);
                setIsLoading(false)

            });
    };
    return (
        <div className="container">
            <BeatLoader
                color={"#79e840"}
                cssOverride={window.innerWidth <= 768 ? mobileOverride : override}
                loading={isLoading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            <div className="left">
                <div className="header">
                    <h2 className="animation a1">Welcome Back</h2>
                    <h4 className="animation a2">
                        Log in to your gearup account using email and password
                    </h4>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        name="email"
                        value={adminDetails.email}
                        onChange={handleInputChange}
                        className="form-field animation a3"
                        placeholder="Email Address"
                    ></input>
                    <input
                        type="password"
                        name="passwordHash"
                        value={adminDetails.passwordHash}
                        onChange={handleInputChange}
                        className="form-field animation a4"
                        placeholder="Password"
                    ></input>

                    <p className="animation a5">
                        <a href="#">Forgot Password</a>
                    </p>
                    <button type="submit" className="animation a6">
                        LOGIN
                    </button>
                </form>
            </div>
            <div className="right"></div>
        </div>
    );
}

export default Login;
