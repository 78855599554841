import { IS_OWNER} from "../actionTypes/ActionTypes";



const initialState =false

const isOwnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_OWNER:
            return action.payload;
        default:
            return state;
    }
};

export default isOwnerReducer;
