import { ADD_FUTSAL_ID} from "../actionTypes/ActionTypes";



const initialState = {
    futsalId: 0
};

const futsalIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FUTSAL_ID:
            return action.payload;
        default:
            return state;
    }
};

export default futsalIdReducer;
