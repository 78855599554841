import React, {useState, useEffect} from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import '../../css/Report.css'
import {axiosInstance} from "../../utils/axiosInstance";
import {endPoints} from "../../utils/endPoints";
import {useFutsal} from "../../reducers/ui/FutsalHook";



const monthNames = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
};


const Report = (props) => {
    const apiPort = process.env.REACT_APP_PORT_KEY;
    let [futsal,setFutsal]=useFutsal();


    console.log("iddd",props.id)


    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        // Fetch data from the backend API
        const endPoint = endPoints.GET_BOOKINGS;
        axiosInstance.get(`${endPoint}${props.id}`)
            .then(response => {
                const formattedData = formatData(response.data.allBookings);
                console.log("booking data",response.data.allBookings)
                setChartData(formattedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);


    const formatData = (data) => {
        const transformedData = {};

        data.forEach(entry => {
            const playTime = new Date(entry.playStartTime);
            const month = playTime.getMonth() + 1;

            if (!transformedData[month]) {
                transformedData[month] = {
                    month: monthNames[month],
                };
            }

            futsal.courts.forEach(court => {
                const courtName = `COURT_${court.courtName}`;

                if (!transformedData[month][courtName]) {
                    transformedData[month][courtName] = 0; // Initialize the court's price counter
                }

                if (entry.courtName === court.courtName) {
                    transformedData[month][courtName] += entry.price;
                }
            });
        });

        return Object.values(transformedData);
    };
    const generateLines = () => {
        if (!futsal.courts) return null;

        return futsal.courts.map((court, index) => {
            const courtName = `COURT_${court.courtName}`;
            return (
                <Line
                    key={index}
                    type="monotone"
                    dataKey={courtName}
                    stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} // Generate random stroke color
                    strokeWidth={2}
                />
            );
        });
    };




    return (
      <div className="report-container">
          <LineChart width={1000} height={600} data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              {generateLines()}
          </LineChart>
      </div>

    );
};

export default Report;
