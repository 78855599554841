import { ADD_FUTSAL} from "../actionTypes/ActionTypes";


const initialState = {
    futsalId: 0,
    name: "",
    address:"",
    place:"",
    contact: {
        email:"",
        mobile:"",
    },
    startTime: "",
    endTime: "",
    courtType: "",
    numberOfCourt:0,
    pricePerHour:{
        day:0,
        night:0
    },
    courts:[""],
    available: false
};

const futsalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FUTSAL:
            return action.payload;
        default:
            return state;
    }
};

export default futsalReducer;
