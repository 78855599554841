import { ADD_BOOKINGS } from "../actionTypes/ActionTypes";

const initialState = {
    bookingId: "",
    userName: "",
    email:"",
    futsalId:0,
    bookingTime:null,
    playStartTime: null,
    playEndTime: null,
    startTime: "",
    endTime: "",
    courtName: "",
    price:0,
    status:""
}

const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BOOKINGS:
            return action.payload;
        default:
            return state;
    }
};

export default bookingReducer;
